<template>
  <!-- Modal -->
<div v-if="modalName" class="modal fade" :id="modalName" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{title ? title : ''}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="desktop" class="modal-body">
          <div class="d-flex flex-row justify-content-around align-items-center">
            <div v-for="(image, index) of img" :key="index">
                <img class="img-fluid p-2" :src="`${publicPath}`+image" alt="ibancar">
            </div>
          </div>
      </div>
      <div id="mobile" class="modal-body">
          <div class="d-flex flex-column justify-content-around align-items-center">
            <div v-for="(image, index) of img" :key="index">
                <img class="img-fluid p-2" :src="`${publicPath}`+image" alt="ibancar">
            </div>
          </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: 'ModalGenerica',
    props: {
        modalName: String,
        title: String,
        img: Array
    },
    data() {
        return {
            publicPath: process.env.BASE_URL
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/styles/variables.scss';

    .modal-header {
        background-color: $blue;
        color: $white;
    }
    .modal-content {
      border: none;
    }
    #exampleModalLabel {
        font-size: 1.15rem;
    }
    .close {
        color: $white;
        text-shadow: 0 1px 0 $white;
    }
  @include media-breakpoint-between(xs, sm) {
      #desktop {
          display: none;
      }
  }
  @include media-breakpoint-between(md, lg) {
      #desktop {
          display: none;
      }
  }
  @include media-breakpoint-between(xl, xxl) {
      #mobile {
          display: none;
      }
  }
</style>