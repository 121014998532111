<template>
      <div class="bg d-flex row justify-content-center align-items-center">
        <div class="">
          <h2 class="title title-1"><strong>¿Cómo escanear documentos de forma fácil con el móvil?</strong></h2>
        </div>
      </div>
      <div id="link" class="d-flex flex-row justify-content-end p-2">
            <a href="#" @click="$router.go(-1)"><p><small>Haz clic para volver a la sección anterior</small></p></a>
      </div>
        <div class="container-fluid pt-3">
            <div class="row justify-content-center padding align-items-center">
                <h4 class="h4-blue pr-3">Como escanear con Android</h4>
                <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32.3434 15.8479H7.99435C7.45272 15.8479 7.01367 16.2869 7.01367 16.8286V37.2472C7.01367 37.7888 7.45272 38.2279 7.99435 38.2279H12.3352V45.109C12.3352 46.7056 13.6295 47.9999 15.2261 47.9999C16.8227 47.9999 18.1171 46.7057 18.1171 45.109V38.2279H22.1605V45.109C22.1605 46.7056 23.4547 47.9999 25.0514 47.9999C26.648 47.9999 27.9423 46.7057 27.9423 45.109V38.2279H32.3435C32.8851 38.2279 33.3242 37.7888 33.3242 37.2472V16.8286C33.324 16.2869 32.885 15.8479 32.3434 15.8479Z" fill="#AAC148"/>
                <path d="M2.89092 15.8416C1.29437 15.8416 0 17.1358 0 18.7325V30.4743C0 32.0708 1.29425 33.3652 2.89092 33.3652C4.48747 33.3652 5.78184 32.0709 5.78184 30.4743V18.7325C5.78173 17.1359 4.48747 15.8416 2.89092 15.8416Z" fill="#AAC148"/>
                <path d="M37.4456 15.8416C35.8491 15.8416 34.5547 17.1358 34.5547 18.7325V30.4743C34.5547 32.0708 35.8489 33.3652 37.4456 33.3652C39.0422 33.3652 40.3365 32.0709 40.3365 30.4743V18.7325C40.3365 17.1359 39.0423 15.8416 37.4456 15.8416Z" fill="#AAC148"/>
                <path d="M8.19068 14.4144H32.0881C32.7115 14.4144 33.1765 13.8402 33.0484 13.23C32.258 9.46247 29.8593 6.28749 26.6045 4.45335L28.6474 0.767287C28.7857 0.517731 28.6955 0.20323 28.446 0.0649755C28.1958 -0.0736279 27.8818 0.0168768 27.7435 0.266433L25.6852 3.98026C23.9993 3.1979 22.1204 2.76095 20.1394 2.76095C18.1583 2.76095 16.2794 3.1979 14.5935 3.98026L12.5351 0.266316C12.3968 0.0166444 12.0823 -0.0733955 11.8328 0.0648594C11.5833 0.203114 11.4931 0.517615 11.6314 0.767171L13.6743 4.45323C10.4195 6.28749 8.02083 9.46236 7.23033 13.23C7.10219 13.8402 7.56714 14.4144 8.19068 14.4144ZM27.2701 8.92107C27.2701 9.53474 26.7726 10.0323 26.1588 10.0323C25.5452 10.0323 25.0476 9.53485 25.0476 8.92107C25.0476 8.30741 25.545 7.8098 26.1588 7.8098C26.7726 7.8098 27.2701 8.30741 27.2701 8.92107ZM14.1197 7.8098C14.7334 7.8098 15.231 8.30729 15.231 8.92107C15.231 9.53474 14.7335 10.0323 14.1197 10.0323C13.5061 10.0323 13.0085 9.53485 13.0085 8.92107C13.0085 8.30741 13.5059 7.8098 14.1197 7.8098Z" fill="#AAC148"/>
                </svg>
            </div>
            <div class="row justify-content-center pt-3 pb-3">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="207" height="413" src="@/assets/img/escaner1.webp" alt="Ibancar">
                    <h4 class="p-4">1. Abre la aplicación Google Drive.</h4>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="208" height="413" src="@/assets/img/escaner2.webp" alt="Ibancar">
                    <h4 class="p-4">2. Abajo a la derecha, toca Añadir.</h4>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="209" height="413" src="@/assets/img/escaner3.webp" alt="Ibancar">
                    <h4 class="p-4">3. Toca Escanear.</h4>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-3">
            <div class="row justify-content-center pb-3">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="207" height="413" src="@/assets/img/escaner4.webp" alt="Ibancar">
                    <h4 class="p-4">4. Haz una foto del documento que quieras escanear.</h4>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="207" height="413" src="@/assets/img/escaner5.webp" alt="Ibancar">
                    <h4 class="p-4">5. Toca Guardar.</h4>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="209" height="413" src="@/assets/img/escaner6.webp" alt="Ibancar">
                    <h4 class="p-4">6. Verifica que todo esté correcto y vuelve a tocar Guardar.</h4>
                </div>
            </div>
        </div>
<!-- IOS -->
        <div class="container-fluid pt-5">
            <div class="row justify-content-center padding align-items-center">
                <h4 class="h4-blue pr-3">Como escanear con Iphone</h4>
                <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.5839 37.4066C38.858 39.0836 37.9988 40.6272 37.0033 42.0464C35.6463 43.9812 34.5352 45.3204 33.6789 46.0641C32.3516 47.2848 30.9294 47.91 29.4065 47.9455C28.3132 47.9455 26.9947 47.6344 25.4599 47.0033C23.9201 46.3752 22.5051 46.0641 21.2112 46.0641C19.8542 46.0641 18.3988 46.3752 16.8421 47.0033C15.2831 47.6344 14.0271 47.9633 13.0668 47.9959C11.6064 48.0581 10.1508 47.4152 8.69779 46.0641C7.77042 45.2552 6.61045 43.8686 5.22087 41.9042C3.72995 39.8065 2.50422 37.374 1.54395 34.6007C0.515539 31.6053 0 28.7046 0 25.8964C0 22.6796 0.695089 19.9052 2.08734 17.5802C3.18153 15.7127 4.63718 14.2396 6.45905 13.1581C8.28092 12.0767 10.2494 11.5256 12.3694 11.4903C13.5293 11.4903 15.0505 11.8491 16.9408 12.5543C18.8258 13.2618 20.0361 13.6206 20.5667 13.6206C20.9635 13.6206 22.308 13.2011 24.5874 12.3647C26.7428 11.589 28.562 11.2678 30.0524 11.3943C34.0908 11.7202 37.1247 13.3122 39.1424 16.1803C35.5307 18.3686 33.7441 21.4337 33.7797 25.3658C33.8122 28.4285 34.9233 30.9771 37.107 33.0008C38.0965 33.94 39.2017 34.6659 40.4313 35.1815C40.1646 35.9548 39.8832 36.6955 39.5839 37.4066ZM30.322 0.960273C30.322 3.36082 29.445 5.60221 27.6969 7.67681C25.5873 10.1431 23.0357 11.5682 20.2687 11.3434C20.2334 11.0554 20.213 10.7523 20.213 10.4338C20.213 8.12924 21.2162 5.66294 22.9978 3.64641C23.8872 2.62541 25.0185 1.77646 26.3903 1.09923C27.7591 0.43211 29.0539 0.063178 30.2716 0C30.3072 0.320916 30.322 0.641852 30.322 0.960242V0.960273Z" fill="#A9A9A9"/>
                </svg>
            </div>
            <div class="row justify-content-center pt-3 pb-3">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="210" height="449" src="@/assets/img/escaner7.webp" alt="Ibancar">
                    <h4 class="p-4">1. Dejar pulsado la aplicación Notas por unos segundos.</h4>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="208" height="446" src="@/assets/img/escaner8.webp" alt="Ibancar">
                    <h4 class="p-4">2. Toca Escanear documentos.</h4>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="206" height="446" src="@/assets/img/escaner9.webp" alt="Ibancar">
                    <h4 class="p-4">3. Haz una foto del documento que quieras escanear.</h4>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-3">
            <div class="row justify-content-center pb-3">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="206" height="448" src="@/assets/img/escaner10.webp" alt="Ibancar">
                    <h4 class="p-4">4. Toca la opción Conservar.</h4>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="206" height="447" src="@/assets/img/escaner11.webp" alt="Ibancar">
                    <h4 class="p-4">5. Toca Guardar.</h4>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-3 col-xxl-3 text-center">
                    <img width="208" height="446" src="@/assets/img/escaner12.webp" alt="Ibancar">
                    <h4 class="p-4">6. Para compartir el documento, toca el ícono de compartir.</h4>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-2">
            <div class="row justify-content-center">
                <button type="button" class="btn-orange"  @click="goTo('/','')">Solicitar préstamo</button>
            </div>
        </div>
        <div class="bg d-flex justify-content-around align-items-center">
            <div>
                <p class="title title-1">Tu coche te avala sin retirada de vehículo, sin cambio de <br> titularidad y sin alquiler por conducir.</p>
            </div>
        </div>
          
</template>

<script>

export default {
  name: "Escaner",
  components:{
  },
  methods: {
    goTo(route) {
      this.$router.push({path: route});
    }
  }
};

</script>

<style scoped lang="scss">

.title {
  width: 100%;
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  line-height: auto;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h4 {
    font-size: 18px;
}


  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs, md) {
    .title-1{
        font-size: 1.1rem;
        line-height: 1.7rem;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .bg {
        margin-top: 67px;
    }
    .btn-orange {
        font-size: 12px;
        height: 40px;
    }
    .padding {
        padding: 0.5rem;
    }
    .h4-blue {
    color: #004680;
    font-size: 1.25rem;
    font-weight: 550;
    text-decoration: underline;
    }
}

@include media-breakpoint-between(md, lg) {
    .title-1{
    font-size: 1.5rem;
    line-height: 55px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .padding {
        padding: 1rem;
    }
    .bg {
        margin-top: 77px;
    }
    .h4-blue {
        color: #004680;
        font-size: 1.25rem;
        font-weight: 550;
        text-decoration: underline;
    }
    #link {
        padding-right: 50px !important;
    }

}

@include media-breakpoint-between(xl, xxl) {
    .title-1{
    font-size: 1.75rem;
    line-height: 50px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .padding {
        padding: 1.5rem;
    }
    .h4-blue {
        color: #004680;
        font-size: 1.5rem;
        font-weight: 550;
        text-decoration: underline;
    }
    #link {
        padding-right: 300px !important;
    }

}

</style>
